.home .carousel-item{
	min-height: 60vh;
	background-position: center;
	background-size: cover;
	position: relative;
	z-index: 1;
}
.home .carousel-item:before{
	content: '';
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: -1;
}
.home .carousel-item .container{
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 50%;
	top:45%;
	transform: translate(-50%,-50%);
	text-align: start;
}
.container{
	display: flex;
	flex-direction: column;
}
.home .carousel-item h2, .home .carousel-item h1{
	font-size: 40px;
	color: #ffffff;
	margin:0 0 0px;
	opacity: 0;
}
.home .carousel-item p{
	font-size: 50px;
	margin:0;
	color: #eeeeee;
	opacity:0;
}
.home .carousel-item.active h2, .home .carousel-item.active h1{
	animation: fadeInLeft 0.5s ease forwards;
}
.home .carousel-item.active p{
	animation: fadeInRight 0.5s ease forwards;
}

@keyframes fadeInLeft{
	0%{
		opacity: 0;
		transform: translateX(-30px);
	}
	100%{
		opacity: 1;
		transform: translateX(0px);
	}
}
@keyframes fadeInRight{
	0%{
		opacity: 0;
		transform: translateX(30px);
	}
	100%{
		opacity: 1;
		transform: translateX(0px);
	}
}


/*responsive*/
@media(max-width: 767px){
  .home .carousel-item h2, .home .carousel-item h2{
  	font-size: 45px;
  }
  .home .carousel-item p{
  	font-size: 22px;
  }
}

.nav-link{
	font-size: 18px;
}


