/* Custom styles for Login2 component */
body {
    font-family: 'Arial', sans-serif;
  }
  
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  p {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .form-control {
    border-radius: 8px;
  }
  
  .btn-primary {
    background-color: #0d6efd;
    border: none;
    border-radius: 8px;
    font-weight: bold;
  }
  
  .btn-outline-secondary {
    border-radius: 8px;
  }
  
  .text-decoration-none {
    color: #0d6efd;
  }
  
  .text-decoration-none:hover {
    text-decoration: underline;
  }