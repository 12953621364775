* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.grid-container {
    display: grid;
    grid-template-columns: 65vw 35vw;
    position: relative;
    text-align: center;
}

.grid-container-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    text-align: center;
}

.grid-container-3 {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    position: relative;
    text-align: center;
}

.signUp-trans:hover {
    background-color: white !important;
    cursor: pointer;
    color: #235183 !important;
}

.signIn-fill:hover {
    background-color: transparent !important;
    color: #235183 !important;
    border: 2px solid #235183 !important;
    cursor: pointer;
}   
.plus-icon:hover{
    background-color: #235183;
    border-radius: 50%;
    color: white;
    padding: 2px 5px;
}