.stories-modal{
    background: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    place-items: center;
    font-size: 0.9rem;
}

.stories-modal .story-card{
    /* background: var(--color-white); */
    background: hsl(252, 30%, 100%);
    padding: 3rem;
    /* border-radius: var(--card-border-radius); */
    border-radius: 1rem;
    width: 30rem;
    height: 38rem;
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    position: relative;
}

.story-card .story-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.story-card .story-head i:hover{
   font-size: 1.3rem;
   color: hsl(252, 75%, 60%);
}

.story-card .story-view{
    width: 100%; 
    display: flex;
    align-items: center;
    margin: 15px 0 15px 0;
}

.story-card .story-view img{
    border: 2px solid hsl(252, 75%, 60%);
    border-radius: 20px;
    /* width: 90%; */
}

.story-head .profile-image{
    width: 3.5rem;
}