.image-container {
    overflow-y: hidden;
    padding: 0;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.form-container{
    height: 100vh;
}
/* If you need to adjust for smaller screens */
@media (max-width: 768px) {
    .col-6 {
      width: 100%;
    }
  
    .image-container, .form-container {
      height: 50vh;
    }
  }