.constructionContainer{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header{
    font-size: 2.9rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .message{
    font-size: 1.4rem;
    font-weight: semibold;
    margin-bottom: 1rem;
  }